import Header from '../components/Header';
import Footer from '../components/Footer';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

function ContactUs() {
    const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
    const LATITUDE = 1.571938;
    const LONGITUDE = 103.763954;
    const GOOGLE_MAPS_URL = 'https://www.google.com/maps/dir//nix+software+enterprise/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x31da6f4939e50e29:0xdf2dc633cd36c91c?sa=X&ved=2ahUKEwiQ6daRmIOAAxWiS2wGHfGNBaYQ9Rd6BAg6EAA&ved=2ahUKEwiQ6daRmIOAAxWiS2wGHfGNBaYQ9Rd6BAhFEAQ';

    return <div className='main'>
        <Header active="contact" />
        <div className='h-24'></div>
        <div className='flex justify-center text-[46px] font-[700] main'>
            Contact Us
        </div>
        <div className='flex justify-center'>
            <div className='w-12 h-0.5 bg-[#c2c2c2]'></div>
        </div>
        <div className='h-16'></div>
        <div className='lg:flex lg:justify-around'>
            <div className='w-[100%] lg:w-1/4 ml-4 lg:ml-0'>
                <div>
                    <span className='text-2xl font-bold'>Nix Software Enterprise</span>&nbsp;<span className='font-bold'>(JR0149758-A)</span><br />
                    <br />
                    Address: 40 Jalan Setia 7/4,<br />
                    Taman Setia Indah,<br />
                    81100 Johor Bahru<br />
                    <a href={GOOGLE_MAPS_URL} className='text-blue-500'>Open In Google Maps</a>
                </div>
                <br />
                <br />
                <div>
                    <span className='text-2xl font-bold'>Person in Charge</span><br />
                </div>
                <div className='mt-2'>
                    <span className='font-bold'>Kent Ng</span><br />
                    Director<br />
                    Phone Number: +6011-1150 1103<br />
                    Email: kent@nixsoft.co
                </div>
            </div>
            <div className='h-24 lg:h-0'></div>
            <div className='w-[100%] lg:w-1/2 h-[400px]'>
                <LoadScript
                    googleMapsApiKey={API_KEY}
                >
                    <GoogleMap
                        mapContainerStyle={{
                            width: '100%',
                            height: '100%',
                        }}
                        center={{
                            lat: LATITUDE,
                            lng: LONGITUDE
                        }}
                        zoom={18}
                    >
                        <Marker position={{ lat: LATITUDE, lng: LONGITUDE }} />
                    </GoogleMap>
                </LoadScript>
            </div>
        </div>
        <div className='h-24'></div>
        <Footer />
    </div>;
}

export default ContactUs;

