import { useNavigate } from 'react-router';

export interface HeaderProps {
    active: 'home' | 'use-case' | 'contact';
}

function Header({
    active,
}: HeaderProps) {
    const navigate = useNavigate();

    return <div
        className="top-0 w-full h-14 bg-[#000000cf]"
        style={{
            position: 'fixed',
            zIndex: 1000,
            left: 0,
            top: 0,
            right: 0,
        }}
    >
        <div className='flex justify-between'>
            <div className='pt-4 pl-16'>
                <button className="text-2xl font-bold text-white" onClick={() => navigate('/')}>Nix Software Enterprise</button>
            </div>
            <div className='sub pt-4 pr-16 text-white flex gap-8 font-[400] text-[14px]'>
                <button className={active === 'home' ? 'font-bold' : ''} onClick={() => navigate('/')}>HOME</button>
                {/* <button className={active === 'use-case' ? 'font-bold' : ''} onClick={() => navigate('/use-case')}>USE CASE</button> */}
                <button className={active === 'contact' ? 'font-bold' : ''} onClick={() => navigate('/contact')}>CONTACT US</button>
            </div>
        </div>
        <div className='mx-16 mt-2 h-[1px] bg-[#777777]'>
        </div>
    </div>;
}

export default Header;