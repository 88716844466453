import { useNavigate } from 'react-router';
import Footer from '../components/Footer';
import Header from '../components/Header';
import './Home.css';

function Home() {
    const navigate = useNavigate();
    return <div className='main'>
        <Header active='home' />
        <div className='bg-1' style={{
            minHeight: '650px',
        }}>
            <div className='flex justify-center pt-60'>
                <h1
                    className='text-white font-bold text-[24px] sm:text-[36px] md:text-[48px] lg:text-[60px] mx-40 text-center'
                    style={{
                        lineHeight: '1.0',
                    }}
                >
                    Malaysia Software Subcontractor
                </h1>
            </div>
            <div className='flex justify-center sub pt-2'>
                <p className='text-white font-[400] text-[22px]'>
                    Bring your ideas to life!
                </p>
            </div>
            <div className='flex justify-center pt-8 sub'>
                <button
                    style={{
                        width: '240px',
                        height: '55px',
                        borderRadius: '5px',
                        color: 'white',
                        fontSize: '15px',
                        fontWeight: '700',
                    }}
                    className='hover:bg-[#606060] bg-[#2a2a2a]'
                    onClick={() => {
                        navigate('/contact');
                    }}
                >
                    VIEW OUR CONTACT
                </button>
            </div>
        </div>
        <div className='h-8'></div>
        <div className='flex justify-center text-[46px] font-[700] main'>
            About Us
        </div>
        <div className='flex justify-center'>
            <div className='w-12 h-0.5 bg-[#c2c2c2]'></div>
        </div>
        <div className='h-8'></div>
        <div className='lg:flex lg:justify-center gap-16'>
            <div className='max-w-[100%] lg:max-w-[350px] mx-8 lg:mx-0 mt-8 lg:mt-0'>
                <div className='flex justify-center'>
                    <img
                        src="https://img1.wsimg.com/isteam/stock/WVDERZr/:/rs=w:730,h:730,cg:true,m/cr=w:730,h:730"
                        className='circle-1 w-64 h-64'
                    ></img>
                </div>
                <div className='sub text-center mt-4 max-w-[100%] lg:max-w-[350px] text-[18px] font-[500]'>Personalized Software</div>
                <p className='sub text-center mt-2 max-w-[100%] lg:max-w-[350px] font-[300]'>At our company, we turn the digital dreams of our clients into a reality. We work closely with our users throughout development to ensure that we are still aligned with the end-goal. We are committed to producing exceptional software for each of our clients.</p>
            </div>
            <div className='max-w-[100%] lg:max-w-[350px] mx-8 lg:mx-0 mt-32 lg:mt-0'>
                <div className='flex justify-center'>
                    <img
                        src="https://img1.wsimg.com/isteam/stock/wNpZpjq/:/rs=w:730,h:730,cg:true,m/cr=w:730,h:730"
                        className='circle-1 w-64 h-64'
                    ></img>
                </div>
                <div className='sub text-center mt-4 max-w-[100%] lg:max-w-[350px] text-[18px] font-[500]'>Professional Team</div>
                <p className='sub text-center mt-2 max-w-[100%] lg:max-w-[350px] font-[300]'>Our team of collaborative experts brings a wealth of experience and diverse skill sets to the table. We believe in the power of effective communication, transparency, and mutual understanding. We take the time to listen to your ideas, understand your unique requirements, and provide tailored solutions that exceed your expectations.</p>
            </div>
            <div className='max-w-[100%] lg:max-w-[350px] mx-8 lg:mx-0 mt-32 lg:mt-0'>
                <div className='flex justify-center'>
                    <img
                        src="https://img1.wsimg.com/isteam/stock/jpoowjO/:/rs=w:730,h:730,cg:true,m/cr=w:730,h:730"
                        className='circle-1 w-64 h-64'
                    ></img>
                </div>
                <div className='sub text-center mt-4 max-w-[100%] lg:max-w-[350px] text-[18px] font-[500]'>Quality Guaranteed</div>
                <p className='sub text-center mt-2 max-w-[100%] lg:max-w-[350px] font-[300]'>The world of technology can be fast-paced and scary. That's why our goal is to provide a high-quality product that aligns with your company's needs. No matter the budget, we pride ourselves on providing professional customer service. We guarantee you will be satisfied with our work.</p>
            </div>
        </div>
        <div className='h-16'></div>
        <Footer />
    </div>;
}

export default Home;